import react, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import femaleAvatar from '../../assets/female_avatar.svg';
import maleAvatar from '../../assets/male_avatar.svg';
import './testimonials.css';
import { testimonials } from '../../assets/testimonials';

const Testimonials = () => {
	const [feedback, setFeedback] = useState(testimonials[0]);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(false);
		setFeedback(testimonials[selectedIndex]);
	}, [selectedIndex]);

	return (
		<section className='bg-white isolate relative'>
			<div className='absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20' />
			<div className='absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-lg shadow-indigo-600/10  ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center' />
			<div className='mx-auto max-w-2xl lg:max-w-4xl fadedown'>
				<div>
					<h2 className='text-lg font-semibold leading-8 tracking-tight text-left text-yellow-500'>
						Feedback
					</h2>
				</div>
				{isLoading ? (
					<figure className='h-[48rem]'></figure>
				) : (
					<figure className='mt-10 fadedown'>
						<p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 text-left sm:text-4xl '>
							{feedback.title}
						</p>
						<blockquote className='text-center text-xl font-semibold leading-8 min-[h-48rem] text-gray-900 sm:text-2xl sm:leading-9'>
							<p className='mt-6 text-lg leading-8 text-gray-400 text-left min-[h-48rem] '>
								<span className='text-3xl text-yellow-500'>"</span>
								{feedback.feedback}
								<span className='text-3xl text-yellow-500'>"</span>
							</p>
						</blockquote>
						<figcaption className='mt-10'>
							<img
								className='mx-auto h-10 w-10 rounded-full '
								src={feedback.gender === 'M' ? maleAvatar : femaleAvatar}
								alt=''
							/>
							<div className='mt-4 flex items-center justify-center space-x-3 text-base'>
								<div className='font-semibold text-gray-900 '>{feedback.name}</div>
								<svg
									viewBox='0 0 2 2'
									width={3}
									height={3}
									aria-hidden='true'
									className='fill-gray-900'
								>
									<circle cx={1} cy={1} r={1} />
								</svg>
								<div className='text-gray-600 '>
									{feedback.role} at {feedback.Company}
								</div>
							</div>
						</figcaption>
					</figure>
				)}
				<div className='mt-10'>
					{testimonials.map((elm: any, index: react.SetStateAction<number>) => {
						return (
							<Button
								key={elm.name}
								className={
									index === selectedIndex
										? 'bg-yellow-500 h-[0.125rem] mb-2 dotButton rounded-full border border-yellow-400 mx-4 hover:border-yellow-500 p-1'
										: 'bg-gray-500 h-[0.125rem] mb-2 dotButton rounded-full border border-gray-400 mx-4 hover:border-yellow-500 p-1'
								}
								onClick={() => {
									setIsLoading(true);
									setSelectedIndex(index);
								}}
							></Button>
						);
					})}
				</div>
			</div>
		</section>
	);
};
export default Testimonials;
