import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { FormContainer } from '@/components/Form/Form';
import { saveAnswers, saveCurrentQuestionId, selectAnswers } from '@/stores/questionSlice';
import { answersObj } from '@/types';

import { Button } from '../Elements/Button';
import { CardGroup } from '../Elements/CardGroup';

export const RoleCharacteristicsForm = (props: any) => {
	const { id } = props;
	const dispatch = useDispatch();
	const options = [
		'Good pay, benefits',
		'Career growth',
		'Supportive culture',
		'Flexibility, balance',
		'Meaningful work',
		'Training, development',
		'Job security',
		'Strong leadership',
		'Recognition, rewards',
		'Shared values, goals',
	];
	const answer = useSelector(selectAnswers) as answersObj;
	const initialValues = {
		newRoleCharacteristics: answer.newRoleCharacteristics || [],
	};

	const schema = Yup.object().shape({
		newRoleCharacteristics: Yup.array().label('newRoleCharacteristics').required().length(3),
	});
	return (
		<>
			<div className='flex items-center justify-center p-5 questionContainer shadow-slate-700'>
				<h1 className='font-bold text-3xl text-left text-white tracking-tight leading-10'>
					3 things that you are looking for in your new role?
				</h1>
			</div>
			<FormContainer
				initialValues={initialValues}
				schema={schema}
				onSubmit={(values: { newRoleCharacteristics: string[] }) => {
					dispatch(
						saveAnswers({
							...answer,
							...values,
						})
					);
					dispatch(saveCurrentQuestionId({ questionId: id + 1 }));
				}}
			>
				{(formikProps: any) => {
					const { isValid, setFieldValue } = formikProps;
					return (
						<div className='flex flex-col h-full justify-between'>
							<div className='flex flex-col h-full justify-center optionsContainer p-4'>
								<CardGroup
									options={options}
									selectedValues={initialValues.newRoleCharacteristics}
									label='newRoleCharacteristics'
									onChange={(e: { value: any }) => {
										setFieldValue('newRoleCharacteristics', e);
									}}
									style='newRoleCharacteristics'
								></CardGroup>
							</div>
							<div className='flex flex-row justify-between px-4 pb-4'>
								<Button
									type='button'
									disabled={id === 1}
									className='flex justify-center disabled:opacity-75 disabled:cursor-not-allowed rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-md bg-white'
									onClick={() => {
										dispatch(
											saveCurrentQuestionId({
												questionId: id - 1,
											})
										);
									}}
								>
									Back
								</Button>
								<Button
									type='submit'
									disabled={!isValid}
									className='flex justify-center disabled:cursor-not-allowed rounded-md questionContainer px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm'
								>
									Save & Continue
								</Button>
							</div>
						</div>
					);
				}}
			</FormContainer>
		</>
	);
};
