import { useEffect, useState } from 'react';

import './App.css';
import { AppProvider } from './providers/app';
import { AppRoutes } from './routes';

function App() {
	const [isTopBarVisible, setTopBarVisible] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleScroll = () => {
		if (window.scrollY > 0) {
			setTopBarVisible(true);
		} else {
			setTopBarVisible(false);
		}
	};
	return (
		<div className='App flex flex-col gap-12'>
			<AppProvider>
				<AppRoutes isTopBarVisible={isTopBarVisible}></AppRoutes>
			</AppProvider>
		</div>
	);
}

export default App;
