import clsx from 'clsx';
import { useField } from 'formik';

import { FieldWrapper } from './FieldWrapper';

export const DateField = (props: any) => {
	const { label, name, placeholder, width, className, disabled = false } = props;
	const [field, meta] = useField(props);
	const { error, touched } = meta;

	return (
		<FieldWrapper
			error={error}
			touched={touched}
			label={label}
			width={width}
			name={name}
			disabled={disabled}
		>
			<input
				type='date'
				id={name}
				placeholder={placeholder}
				{...field}
				className={clsx(
					'flex-1 bg-gray-100 min-w-0 block w-full px-3 py-3 h-11 text-base rounded border placeholder:text-base placeholder:text-grey',
					error && touched
						? 'text-statusInvalidDarkRed border-statusInvalidRed placeholder:text-statusInvalidRed focus:ring-statusInvalidRed focus:border-statusInvalidRed'
						: 'border-greyscale5 text-primaryDarkViolet focus:ring-primaryVioletPressed focus:border-primaryVioletPressed',
					className
				)}
				disabled={disabled}
				aria-invalid='true'
				aria-describedby='email-error'
			/>
		</FieldWrapper>
	);
};
