import {
	fetchBaseQuery,
	createApi,
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import storage from '../utils/storage';

const baseUrl =
	import.meta.env.MODE === 'production'
		? import.meta.env.VITE_API_BASE_URL_PRODUCTION
		: import.meta.env.VITE_API_BASE_URL_DEVELOPMENT;
const baseQuery = fetchBaseQuery({
	baseUrl: baseUrl,
	prepareHeaders: (headers) => {
		const token = storage.getCookie();
		if (token) {
			headers.set('authorization', `${token}`);
		}
		return headers;
	},
});

const baseQueryWithAutoLogOut: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	const result = await baseQuery(args, api, extraOptions);
	if (result.error && result.error.status === 401) {
		console.log('no headers');
	}
	return result;
};

export const baseApi = createApi({
	baseQuery: baseQueryWithAutoLogOut,
	endpoints: () => ({}),
});
