import { RadioButton } from './RadioButton';

type RadioGroupProps = {
	className?: string;
	groupLabel: string;
	subTitle?: string;
	radioValues: {
		name: string;
		label: string;
		value: string | number;
	}[];
};

export const RadioGroup = (props: RadioGroupProps) => {
	const { groupLabel, radioValues, className, subTitle } = props;

	const renderRadioButtons = () => {
		return radioValues.map((radioValue, index) => {
			const { label, name, value } = radioValue;
			return (
				<RadioButton
					key={index}
					label={label}
					name={name}
					value={value}
					className={className}
				/>
			);
		});
	};

	return (
		<div className='flex flex-col py-2 text-left mb-6'>
			<label className='block text-sm font-medium text-dark'>{groupLabel}</label>
			<p className='my-2 text-gray-500 text-xs'>{subTitle}</p>
			<div
				role='group'
				aria-labelledby='my-radio-group'
				className='align-middle flex flex-col justify-around'
			>
				{renderRadioButtons()}
			</div>
		</div>
	);
};
