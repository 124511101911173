import curious from '../assets/curious.svg';
import hybrid from '../assets/hybrid.svg';
import jobHappy from '../assets/jobHappy.svg';
import office from '../assets/office.svg';
import remote from '../assets/remote.svg';
import search from '../assets/search.svg';

export const IconConsts = (props: any) => {
	const { name } = props;
	switch (name) {
		case 'Remote':
			return <img src={remote} alt='CardImage' className='h-40 w-40' />;
		case 'Hybrid':
			return <img src={hybrid} alt='CardImage' className='h-40 w-40' />;
		case 'Actively Searching':
			return <img src={search} alt='CardImage' className='h-40 w-40' />;
		case 'Just curious about what’s out there':
			return <img src={curious} alt='CardImage' className='h-40 w-40 object-cover' />;
		case 'I’m happy at where I am now but might move for the perfect role':
			return <img src={jobHappy} alt='CardImage' className='h-40 w-40' />;
		case 'In Person':
			return <img src={office} alt='CardImage' className='h-40 w-40 object-cover' />;
		default:
			return <></>;
	}
};
