import clsx from 'clsx';
import { useField } from 'formik';

export const InputField = (props: any) => {
	const { label } = props;
	const [field, meta] = useField(props);
	return (
		<>
			<label className='text-base font-medium mb-2 items-start flex justify-start'>
				{label}
			</label>
			<input
				{...field}
				{...props}
				className={clsx(
					'flex-1 min-w-0 block w-full p-2 mb-2 ring-slate-300 ring-inset  text-base rounded border-gray-400 placeholder:text-gray-400 focus:outline-none',
					meta.error && meta.touched
						? ' text-red-700 border-red-700 ring-1 placeholder:text-red-700 focus:ring-red-700 focus:border-red-700'
						: 'focus:border-yellow-400 ring-1 focus:ring-yellow-400'
				)}
			/>
			{meta.touched && meta.error ? (
				<p className='text-red-700 text-sm mb-2 text-left'>*{meta.error}</p>
			) : null}
		</>
	);
};
