import { ShieldCheckIcon } from '@heroicons/react/24/solid';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import jobBoard from '../../../assets/jobBoard.jpg';
import registrationForm from '../../../assets/registrationform.jpg';
import resumeUpload from '../../../assets/resumeUpload.jpg';
import signUp from '../../../assets/signUp.jpg';

const steps = [
	{
		title: 'Login Or Register',
		subTitle: 'You are one step closer to your dream job.',
		image: signUp,
		features: [
			{
				name: 'Streamlined Hiring Process',
				description:
					'Simplify and expedite the process of finding and hiring top talent by leveraging our collaborative workflow',
			},
			{
				name: 'Efficient Team Collaboration',
				description:
					'Seamlessly work together with your teams to identify, evaluate, and select the perfect candidate for your organization.',
			},
		],
	},
	{
		title: 'Fill In Your Personal Details',
		subTitle: 'Tell us more about you so that we can find you the perfect role.',
		image: registrationForm,
		features: [
			{
				name: 'Enhanced Candidate Selection',
				description:
					'Utilize our platform to effortlessly assess and compare applicants, ensuring you make informed decisions and secure the best fit for your team.',
			},
			{
				name: 'Seamless Application Experience',
				description:
					'Provide candidates with a user-friendly and intuitive application process, making it easier for them to apply and showcase their qualifications',
			},
		],
	},
	{
		title: 'Upload Your Resume',
		subTitle: 'This part is key! A good resume is equal to a good job application.',
		image: resumeUpload,
		features: [
			{
				name: 'Streamlined Candidate Evaluation',
				description:
					'Effortlessly evaluate applicant profiles, share feedback, and streamline the decision-making process, enabling faster and more effective hiring outcomes',
			},
			{
				name: 'Optimized Recruitment Efficiency',
				description:
					'Maximize your recruitment efficiency by leveraging our collaborative tools, enabling effective communication and coordination among team members',
			},
		],
	},
	{
		title: 'Your Perfect Match',
		subTitle: 'Leave the rest to us! We will find your perfect match and be back in touch.',
		image: jobBoard,
		features: [
			{
				name: 'Accelerated Hiring Timelines',
				description:
					'Collaborative hiring processes enable quicker turnaround times, allowing you to secure top talent before your competitors',
			},
			{
				name: 'Improved Candidate Experience',
				description:
					'Foster positive candidate experiences by offering a streamlined, transparent, and engaging hiring journey, from application to onboarding',
			},
		],
	},
];
export const StepsContainer = () => {
	const [currentStep, setCurrentStep] = useState(0);
	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentStep((index) => (index + 1) % steps.length);
		}, 5000); // change value here to set the interval in milliseconds

		return () => clearInterval(intervalId);
	}, []);
	return (
		<>
			<AnimatePresence mode='wait'>
				<motion.div
					key={currentStep}
					initial={{ y: 10, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					exit={{ y: -10, opacity: 0 }}
					transition={{ duration: 0.5 }}
				>
					<div className='bg-white border gap-10 min-h-[40rem] lg:min-h-[22rem] lg:grid lg:grid-cols-2 flex flex-wrap m-4 overflow-hidden max-w-2xl p-4 shadow sm:rounded-lg'>
						<div className='p-4 rounded-lg imageContainer'>
							<img
								src={steps[currentStep].image}
								alt='form screenshot'
								className='ring-gray-400/10 rounded-xl shadow-xl w-100'
							/>
						</div>
						<div className='text-left h-100 grid'>
							<div>
								<p className='text-sm text-yellow-500'>0{currentStep + 1}</p>
								<div className=''>
									<p className='font-semibold text-xl '>
										{steps[currentStep].title}
									</p>
								</div>

								<p className='mb-6 text-xs text-gray-600 '>
									{steps[currentStep].subTitle}
								</p>
								<div className='h-px flex-auto bg-gray-100 mb-2 ' />
							</div>
							<div className='grid grid-rows-2 h-100'>
								{steps[currentStep].features.map((elm, index) => (
									<div className='flex flex-row mb-2' key={index}>
										<ShieldCheckIcon
											className='w-12 h-12 text-yellow-500 mr-3'
											aria-hidden='true'
										/>
										<div className='flex flex-col'>
											<p className='font-semibold text-sm'>{elm.name}</p>
											<span className=' text-gray-500 text-xs'>
												{elm.description}
											</span>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</motion.div>
			</AnimatePresence>
			<div className='grid grid-cols-4 gap-4 max-w-2xl p-4'>
				{steps.map((elm, index) => (
					<div key={index} className='flex flex-col items-start'>
						<div
							className={
								index === currentStep
									? 'bg-yellow-500 h-[0.125rem] mb-2 rounded w-100'
									: 'bg-gray-500 h-[0.125rem] mb-2 rounded w-100'
							}
						></div>
						<p
							className={
								index === currentStep ? 'text-sm text-yellow-500' : 'text-sm'
							}
						>
							0{index + 1}
						</p>
						<dt
							className={
								index === currentStep
									? 'font-semibold text-sm text-yellow-500 text-left'
									: 'font-semibold text-black text-sm text-left'
							}
						>
							{elm.title}
						</dt>
						<dd className='mt-2 text-gray-400 text-xs text-left'>{elm.subTitle}</dd>
					</div>
				))}
			</div>
		</>
	);
};
