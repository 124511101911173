import { useEffect, useState } from 'react';

import { Button } from './Button';

export const CardGroup = (props: any) => {
	const { options, style, onChange, selectedValues } = props;
	const [selectedArray, setSelected] = useState(selectedValues);

	useEffect(() => {
		onChange(selectedArray);
	}, [selectedArray]);

	return (
		<div className={`${style === 'vertical' ? 'px-2 py-3 w-full ' : 'w-full px-2 py-8 '}`}>
			<div className='mx-auto w-full'>
				<div className={`${style === 'vertical' ? 'flex flex-wrap' : 'grid grid-cols-2'}`}>
					{options.map((option: string) => (
						<Button
							type='button'
							key={option}
							onClick={() => {
								if (selectedArray.indexOf(option) >= 0) {
									setSelected([
										...selectedArray.filter((elm: string) => elm !== option),
									]);
								} else {
									selectedArray.length < 3 &&
										setSelected([...selectedArray, option]);
								}
							}}
							className={`${
								selectedArray.indexOf(option) >= 0
									? 'bg-yellow-500 bg-opacity-75 text-white cursor-pointer'
									: `${
											selectedArray.length === 3
												? 'cursor-not-allowed'
												: 'cursor-pointer'
									  } bg-white`
							} relative flex  rounded-lg px-2 py-2 m-2 shadow-md focus:outline-none`}
						>
							<>
								<div className='flex w-full items-center justify-between'>
									<div className='flex flex-col justify-center items-center  w-full'>
										<div className='flex items-center'>
											<div className='text-xs'>
												<p
													className={`font-medium px-2  ${
														selectedArray.indexOf(option) >= 0
															? 'text-white'
															: 'text-gray-900'
													}`}
												>
													{option}
												</p>
											</div>
										</div>
									</div>

									{selectedArray.indexOf(option) >= 0 && (
										<div className='shrink-0 text-white'>
											<CheckIcon className='h-3 w-3' />
										</div>
									)}
								</div>
							</>
						</Button>
					))}
				</div>
			</div>
		</div>
	);
};
function CheckIcon(props: any) {
	return (
		<svg viewBox='0 0 24 24' fill='none' {...props}>
			<circle cx={12} cy={12} r={12} fill='#fff' opacity='0.2' />
			<path
				d='M7 13l3 3 7-7'
				stroke='#fff'
				strokeWidth={1.5}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
