import { useEffect, useState } from 'react';
import './Counter.css'; // Make sure to include your CSS file

interface ICounterComponent {
	Value: number;
}

const CounterComponent = (props: ICounterComponent) => {
	const { Value } = props;
	const [endValue, setEndValue] = useState<number>(Value); // Change this to your desired end value
	const [counter, setCounter] = useState<number>(0);

	useEffect(() => {
		const animationDuration: number = 5 * 1000; // 10 seconds
		const animationSteps = 100;
		const stepDuration: number = animationDuration / animationSteps;

		let currentStep = 0;
		// eslint-disable-next-line prefer-const
		let intervalId: NodeJS.Timeout;

		const updateCounter = () => {
			const stepValue: number = (endValue / animationSteps) * (currentStep + 1);
			setCounter(Math.round(stepValue));

			if (currentStep < animationSteps - 1) {
				currentStep += 1;
			} else {
				clearInterval(intervalId);
			}
		};

		intervalId = setInterval(updateCounter, stepDuration);

		// Cleanup the interval on component unmount
		return () => clearInterval(intervalId);
	}, [endValue]);

	return (
		<div className='body'>
			<div className='counter mr-2 text-8xl font-bold'>{counter}</div>
		</div>
	);
};

export default CounterComponent;
