import { Link } from 'react-router-dom';

import { DialogContainer } from '../Elements';

export const PrivacyPolicy = (props: any) => {
	const { closeModal, isOpenModal } = props;
	return (
		<>
			<DialogContainer
				isOpen={isOpenModal}
				Title='PRIVACY POLICY'
				subTitle='Please read and accept the privacy policy to continue.'
				buttonText='Edit Profile'
				closeModal={closeModal}
				isFullWidth={true}
			>
				<div className='h-full my-3 overflow-x-auto text-sm'>
					<p>
						This privacy policy explains how J&T Recruitment uses any personal
						information we collect about you when you use this website.{' '}
					</p>
					<ul className='list-disc p-4'>
						<li>
							Information you put into forms on our site or files you upload on our
							site, such as a CV
						</li>
						<li>
							A record of any correspondence between us or engage directly with J&T
							consultants
						</li>
						<li>Details of your visits to our site and the resources you use</li>
						<li>
							Information about your computer (e.g. your IP address, browser,
							operating system, etc.) for system administration, security, and fraud
							prevention purposes
						</li>
					</ul>
					<p>
						Under GDPR we will ensure that your personal data is processed lawfully,
						fairly, and transparently, without adversely affecting your rights.
					</p>
					<p className='my-2 font-bold'>HOW WILL WE USE THE INFORMATION ABOUT YOU?</p>
					<p>
						This information is used to continually improve the quality of the service
						that we provide to you and where relevant, to ensure that we keep you up to
						date with relevant information.
					</p>
					<p className='my-2 font-bold'>EMAIL COMMUNICATIONS</p>
					<p>
						We would like to send you information relevant to your circumstances and the
						other services that we provide. You have a right at any time to stop us from
						contacting you. If you no longer wish to receive electronic communications
						from J&T Recruitment, please send us an email to:
						<a
							href='mailto:info@jtrec.co.uk'
							className='px-1 text-yellow-600 underline'
						>
							info@jtrec.co.uk
						</a>
					</p>
					<p>
						Candidates of J&T Recruitment will continue to receive emails relating to
						the administration and servicing of their active engagement with us.
					</p>
					<p className='my-2 font-bold'>ACCESS TO YOUR INFORMATION</p>
					<p>
						You have the right to request a copy of the information that we hold about
						you. If you would like a copy of some or all of your personal information,
						please email{' '}
						<a
							href='mailto:info@jtrec.co.uk'
							className='px-1 text-yellow-600 underline'
						>
							info@jtrec.co.uk
						</a>{' '}
						. We want to make sure that your personal information is accurate and up to
						date. You may ask us to correct or remove information you think is
						inaccurate.
					</p>
					<p className='my-2 font-bold'>COOKIES</p>
					<p>
						Cookies are text files placed on your computer to collect standard internet
						log information and visitor behaviour information. This information is used
						to track visitor use of the website, enrich your user experience, and to
						compile statistical reports on website activity. For further information
						visit
						<Link
							to={'https://www.aboutcookies.org/'}
							className='px-1 text-yellow-600 underline'
						>
							www.aboutcookies.org
						</Link>{' '}
						or{' '}
						<Link
							to={'https://www.allaboutcookies.org/'}
							className='px-1 text-yellow-600 underline'
						>
							{' '}
							www.allaboutcookies.org
						</Link>{' '}
						www.allaboutcookies.org. You can set your browser not to accept cookies and
						the above websites tell you how to remove cookies from your browser.
						However, in a few cases, some of our website features may not function
						properly as a result.
					</p>
					<p className='mt-2'>
						By continuing to use this website, you are consenting to our use of Cookies
						and the J&T RecruitmentPrivacy Policy. Should you not wish to consent to
						these policies, you should discontinue accessing this website.
					</p>
					<p className='my-2 font-bold'>OTHER WEBSITES</p>
					<p>
						Our website may contain links to other websites. This privacy policy only
						applies to this website so when you link to other websites you should read
						their own privacy policies.
					</p>
					<p className='my-2 font-bold'>CHANGES TO OUR PRIVACY POLICY</p>
					<p>
						We keep our privacy policy under regular review and we will place any
						updates on this web page.
					</p>
				</div>
				<div className='flex flex-row justify-between mt-4'>
					<button
						type='button'
						className='border border-yellow-500 text-lg fadedown flex font-medium items-center justify-center px-4 py-2 rounded-md shadow-sm sm:ml-3 sm:text-sm sm:w-auto text-yellow-500'
						onClick={() => {
							closeModal(false);
						}}
					>
						Reject
					</button>
					<button
						type='button'
						className='rounded-md bg-yellow-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
						onClick={() => {
							closeModal(true);
						}}
					>
						Accept
					</button>
				</div>
			</DialogContainer>
		</>
	);
};
