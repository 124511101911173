import { useSelector } from 'react-redux';

import { CompanySizeForm } from '@/components/Questions/CompanySizeForm';
import { ExperienceForm } from '@/components/Questions/ExperienceForm';
import { FavouriteIndustry } from '@/components/Questions/FavouriteIndustries';
import { FavouriteTechnologies } from '@/components/Questions/favouriteTechnologies';
import { IdealRolesForm } from '@/components/Questions/IdealRolesForm';
import { IndustriesNotWork } from '@/components/Questions/IndustriesNotWantTowork';
import { JobSettings } from '@/components/Questions/JobSettings';
import { RoleCharacteristicsForm } from '@/components/Questions/RoleCharacteristicsForm';
import { SalaryForm } from '@/components/Questions/Salary';
import { SearchIntensityForm } from '@/components/Questions/SearchIntensityForm';
import { FavouriteNotTechnologies } from '@/components/Questions/TechnologiesNotWantTowork';
import { VisaRequirementsForm } from '@/components/Questions/VisaRequirementsForm';
import { selectAnswers, selectCurrentQuestionId } from '@/stores/questionSlice';
import { answersObj } from '@/types';

import './QuestionCard.css';

export const QuestionCard = () => {
	const questionId = useSelector(selectCurrentQuestionId);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const answer = useSelector(selectAnswers) as answersObj;
	const multiStep = (currentStep: number) => {
		switch (currentStep) {
			case 1:
				return <RoleCharacteristicsForm id={1} />;
			case 2:
				return <JobSettings id={2} />;
			case 3:
				return <VisaRequirementsForm id={3} />;
			case 4:
				return <SearchIntensityForm id={4} />;
			case 5:
				return <IdealRolesForm id={5} />;
			case 6:
				return <ExperienceForm id={6} />;
			case 7:
				return <CompanySizeForm id={7} />;
			case 8:
				return <FavouriteIndustry id={8} />;
			case 9:
				return <IndustriesNotWork id={9} />;
			case 10:
				return <FavouriteTechnologies id={10} />;
			case 11:
				return <FavouriteNotTechnologies id={11} />;
			case 12:
				return <SalaryForm id={12} />;
			default:
				return <RoleCharacteristicsForm id={1} />;
		}
	};

	return (
		<div className='min-h-[34rem] mt-16 relative w-100 w-full'>
			<div className='backgroundContianer absolute gap-10 grid grid-cols-2 min-h-[34rem] rotate-[5deg] w-100 my-4 overflow-hidden shadow sm:rounded-lg'></div>
			<div className='backgroundContianer absolute  gap-10 grid grid-cols-2 min-h-[34rem] -rotate-[5deg] w-100 my-4 overflow-hidden shadow sm:rounded-lg'></div>
			<div
				className={`bg-white absolute  min-h-[34rem] w-100 my-4 overflow-hidden shadow sm:rounded-lg gap-4 grid grid-cols-2`}
			>
				{multiStep(questionId)}
			</div>
		</div>
	);
};
