import small10 from '../assets/compnaySize/1-10.svg';
import extraLarge from '../assets/compnaySize/extraLarge.svg';
import large from '../assets/compnaySize/large.svg';
import medium from '../assets/compnaySize/medium.svg';
import startUp from '../assets/compnaySize/startUp.svg';
import entry from '../assets/workExperience/entry.svg';
import internship from '../assets/workExperience/internship.jpg';
import junior from '../assets/workExperience/junior.jpg';
import leadership from '../assets/workExperience/leadership.jpg';
import mid from '../assets/workExperience/mid.jpg';
import senior from '../assets/workExperience/senior.jpg';

export const switchImage = (option: string) => {
	switch (option) {
		case 'Internships':
			return {
				image: internship,
				value: 0,
			};
		case 'Entry Level/Graduate':
			return {
				image: entry,
				value: 1,
			};

		case 'Junior (1-2 years)':
			return {
				image: junior,
				value: 2,
			};

		case 'Mid Level (3-4 years)':
			return {
				image: mid,
				value: 3,
			};

		case 'Senior (5-8 years)':
			return {
				image: senior,
				value: 4,
			};

		case 'Leadership (9+ Years)':
			return {
				image: leadership,
				value: 5,
			};

		case '1-10 Micro Org':
			return {
				image: small10,
				value: 0,
			};
		case '10-50 StartUp':
			return {
				image: startUp,
				value: 1,
			};
		case '50-250 Medium-Sized Org':
			return {
				image: medium,
				value: 2,
			};
		case '250-1000 Large Org':
			return {
				image: large,
				value: 3,
			};
		case '1000+ Employees':
			return {
				image: extraLarge,
				value: 4,
			};

		default:
			return {
				image: internship,
				value: 0,
			};
	}
};
