import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { saveAnswers, saveCurrentQuestionId, selectAnswers } from '@/stores/questionSlice';
import { answersObj } from '@/types';

import { Button } from '../Elements/Button';
import { FormContainer } from '../Form/Form';
import { RangeInput } from '../Form/RangeInput';

export const ExperienceForm = (props: any) => {
	const answer = useSelector(selectAnswers) as answersObj;
	const { id } = props;
	const dispatch = useDispatch();
	const initialValues = {
		ExperienceLevel: answer.ExperienceLevel || '',
	};
	const options = [
		'Internships',
		'Entry Level/Graduate',
		'Junior (1-2 years)',
		'Mid Level (3-4 years)',
		'Senior (5-8 years)',
		'Leadership (9+ Years)',
	];
	const schema = Yup.object().shape({
		ExperienceLevel: Yup.string().label('ExperienceLevel').required(),
	});
	return (
		<>
			<div className='flex items-center justify-center p-5 questionContainer shadow-slate-700'>
				<h1 className='font-bold text-3xl text-left text-white tracking-tight leading-10'>
					What level of experience are you looking for?
				</h1>
			</div>
			<FormContainer
				initialValues={initialValues}
				schema={schema}
				onSubmit={(values: any) => {
					dispatch(
						saveAnswers({
							...answer,
							...values,
						})
					);
					dispatch(saveCurrentQuestionId({ questionId: id + 1 }));
				}}
			>
				{(formikProps: any) => {
					const { isValid } = formikProps;
					return (
						<div className='flex flex-col h-full justify-between'>
							<div className='flex flex-col h-full justify-center optionsContainer p-4'>
								<RangeInput
									label='Experience'
									name='ExperienceLevel'
									min={0}
									max={5}
									step={1}
									options={options}
								></RangeInput>
							</div>
							<div className='flex flex-row justify-between px-4 pb-4'>
								<Button
									type='button'
									disabled={id === 0}
									className='flex justify-center disabled:opacity-75 disabled:cursor-not-allowed rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-md bg-white'
									onClick={() => {
										dispatch(
											saveCurrentQuestionId({
												questionId: id - 1,
											})
										);
									}}
								>
									Back
								</Button>
								<Button
									type='submit'
									disabled={!isValid}
									className='flex justify-center disabled:cursor-not-allowed rounded-md questionContainer px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm'
								>
									Save & Continue
								</Button>
							</div>
						</div>
					);
				}}
			</FormContainer>
		</>
	);
};
