import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';

import { store } from '../stores/store';

type AppProviderProps = {
	children: React.ReactNode;
};

export const AppProvider = (props: AppProviderProps) => {
	const { children } = props;
	return (
		<Provider store={store}>
			<Router>{children}</Router>
		</Provider>
	);
};
