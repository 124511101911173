import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';

import { IconConsts } from '@/consts/IconConsts';

import { Button } from './Button';
export const IconCardGroup = (props: any) => {
	const { options, style, onChange, selectedValues } = props;
	const [selectedArray, setSelected] = useState(selectedValues);
	useEffect(() => {
		onChange(selectedArray);
	}, [selectedArray]);

	return (
		<div className={`${style === 'vertical' ? 'px-2 py-3 w-full ' : 'w-full px-2 py-8 '}`}>
			<div className='mx-auto w-full'>
				<div className={'flex flex-wrap justify-center items-center'}>
					{options.map((option: string) => (
						<div key={option} className='relative z-0'>
							<Button
								type='button'
								onClick={() => {
									if (selectedArray.indexOf(option) >= 0) {
										setSelected([
											...selectedArray.filter(
												(elm: string) => elm !== option
											),
										]);
									} else {
										selectedArray.length < 3 &&
											setSelected([...selectedArray, option]);
									}
								}}
								className={`${
									selectedArray.indexOf(option) >= 0
										? 'border-yellow-500 border-2 bg-opacity-75 text-white cursor-pointer'
										: `${
												selectedArray.length === 3
													? 'cursor-not-allowed'
													: 'cursor-pointer'
										  } bg-white`
								} relative flex  rounded-lg px-2 py-2 m-2 shadow-md focus:outline-none z-0`}
							>
								<>
									<div className='flex w-full items-center justify-between'>
										<div className='flex flex-col justify-center items-center w-full'>
											<IconConsts name={option} />
											<div className='flex items-center'>
												<div className='text-xs'>
													<p className='font-medium px-2 text-black w-44'>
														{option}
													</p>
												</div>
											</div>
										</div>
									</div>
								</>
							</Button>
							{selectedArray.indexOf(option) >= 0 && (
								<div className='absolute right-4 shrink-0 top-4 z-50'>
									<CheckCircleIcon className='h-6 w-6 text-yellow-500' />
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
