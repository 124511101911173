import { useField } from 'formik';
import React, { useState } from 'react';

import './SalaryRange.css';
import { FieldWrapper } from './FieldWrapper';

export const SalarySlider = (props: any) => {
	const { label, name, width, disabled = false, title } = props;
	const [sliderValue, setSliderValue] = useState<number>(50);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(props);
	const { error, touched } = meta;
	const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = parseInt(event.target.value, 10);
		setSliderValue(newValue);
		helpers.setValue(`£${newValue},000`);
	};

	const calculateCubeWidth = (): string => {
		return `${sliderValue}%`;
	};

	return (
		<FieldWrapper
			error={error}
			touched={touched}
			label={label}
			width={width}
			name={name}
			disabled={disabled}
		>
			<section id='content'>
				<p className='my-2 text-gray-500 text-xs'>{title}</p>
				<div className='cube'>
					<div className='a' style={{ width: calculateCubeWidth() }}></div>
					<div className='b' style={{ width: calculateCubeWidth() }}></div>
					<div className='c' style={{ width: calculateCubeWidth() }}></div>
					<div className='d' style={{ width: calculateCubeWidth() }}></div>
					<input
						type='range'
						min={0}
						max={100}
						step={1}
						value={sliderValue}
						onChange={handleSliderChange}
						id='slider-range-min'
						className='ui-slider'
					/>
				</div>
				<div className='flex flex-row items-center justify-center'>
					<p className='mr-2 text-2xl font-semibold'>Salary:</p>
					<input
						type='text'
						value={`£${sliderValue},000`}
						className='text-2xl'
						readOnly
					/>
				</div>
			</section>
		</FieldWrapper>
	);
};
