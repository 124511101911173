import clsx from 'clsx';
import { useField } from 'formik';

import { FieldWrapper } from './FieldWrapper';

export const SelectField = (props: any) => {
	const {
		label,
		name,
		options,
		className,
		defaultValue,
		placeholder,
		width,
		handleChange,
		disabled,
		dataTestId,
	} = props;
	const [field, meta] = useField(props);
	const { error, touched } = meta;
	return (
		<FieldWrapper
			label={label}
			disabled={disabled}
			error={error}
			touched={touched}
			width={width}
			name={name}
		>
			<select
				placeholder={placeholder}
				id={name}
				disabled={disabled}
				data-test-id={dataTestId}
				className={clsx(
					'mt-1 block bg-gray-100 w-full pl-3 pr-10 py-2 border text-base text-grey rounded focus:outline-none focus:ring-yellow-400 focus:border-yellow-500',
					className
				)}
				defaultValue={defaultValue}
				{...field}
				onChange={(e) => {
					handleChange(e);
					field.onChange(e);
				}}
			>
				<option value='' disabled data-test-id={'optionDefault'}>
					Please select...
				</option>
				{options.map((elm: { label: string; value: string }) => (
					<option key={elm.label?.toString()} value={elm.value}>
						{elm.label}
					</option>
				))}
			</select>
		</FieldWrapper>
	);
};
