import Cookies from 'universal-cookie';
const jwt = 'jt';

const cookies = new Cookies();

const storage = {
	getCookie: () => {
		return cookies.get(`${jwt}Token`);
	},
	setCookie: (token: string, email: string) => {
		cookies.set('jtToken', token, { path: '/', maxAge: 10000 });
		sessionStorage.setItem('jwtToken', JSON.stringify(token));
		sessionStorage.setItem('email', JSON.stringify(email));
	},
};
export default storage;
