import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useAuthenticateUserMutation, useSignUpMutation } from '../../../api/apiSlice';
import hand from '../../../assets/hand.png';
import jandT from '../../../assets/j&t.png';
import image from '../../../assets/loginBackGround.jpg';
import { saveUserDetails } from '../../../stores/questionSlice';
import storage from '../../../utils/storage';
import { Button } from '../../Elements/Button';
import { InputField } from '../../Form/Field';
import { FormContainer } from '../../Form/Form';
import './LoginForm.css';
import { QuestionForm } from '../QuestionForm/QuestionForm';

export const LoginForm = () => {
	const [signUp, { data, isSuccess }] = useSignUpMutation();
	const [authenticate, { isSuccess: isAuthenticated }] = useAuthenticateUserMutation();
	const dispatch = useDispatch();

	const schema = Yup.object().shape({
		email: Yup.string().label('email').email().required('Email is a required Field.'),
		fullName: Yup.string().label('fullName').required('Full name is a required Field.'),
		phoneNumber: Yup.number()
			.label('phoneNumber')
			.required('Phone number is a required Field.'),
	});

	useEffect(() => {
		if (data && isSuccess) {
			storage.setCookie(data.accessToken, data.userDetails);
			dispatch(
				saveUserDetails({
					email: data.userDetails.userName,
					fullName: data.userDetails.fullName,
					phoneNumber: data.userDetails.phoneNumber,
				})
			);
			authenticate();
		}
	}, [authenticate, data, dispatch, isSuccess]);

	return (
		<>
			{isAuthenticated ? (
				<QuestionForm isOpen={!data?.isNewUser}></QuestionForm>
			) : (
				<div className='relative min-h-[34rem] w-100 mt-10'>
					<div className='backgroundContianer absolute gap-10 grid grid-cols-2 min-h-[34rem] rotate-[5deg] w-100 my-4 overflow-hidden shadow sm:rounded-lg'></div>
					<div className='backgroundContianer absolute  gap-10 grid grid-cols-2 min-h-[34rem] -rotate-[5deg] w-100 my-4 overflow-hidden shadow sm:rounded-lg'></div>
					<div className='bg-white absolute gap-4 grid grid-cols-2 min-h-[34rem] w-100 my-4 overflow-hidden shadow sm:rounded-lg'>
						<div className='hidden sm:flex items-center justify-center m-2 questionContainer  rounded-tl-[80px] rounded-br-[80px] shadow-slate-700 relative'>
							<div className='absolute text-left p-4 bottom-0'>
								<h1 className='font-bold text-5xl text-white shadowed-text'>
									Unlock Your Potential. Join Our Talent Network Today!
								</h1>
								<h2 className='mx-2 my-3 text-base text-white shadowed-text'>
									Connecting Talent with Opportunity - Your Journey Starts Here!"
								</h2>
							</div>
							<img
								src={image}
								alt='form screenshot'
								className='h-[34rem] object-cover w-100 rounded-tl-[80px] rounded-br-[80px]'
							/>
						</div>
						<div className='optionsContainer'>
							<>
								<div className='flex min-h-full flex-1 flex-col justify-center p-6 '>
									<div className='sm:mx-auto sm:w-full sm:max-w-sm flex flex-col justify-start justify-self-center items-baseline'>
										<div className='flex flex-col justify-start justify-self-center items-baseline'>
											<img
												className='hidden sm:flex shadow-md h-10 w-auto'
												src={jandT}
												alt='Your Company'
											/>
											<h2 className='mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
												Let’s Get Started
											</h2>
											<img
												className='mx-auto h-5 w-auto ml-2'
												src={hand}
												alt='hand emoji'
											/>
										</div>
										<p className='mt-2 text-sm text-gray-500 text-left'>
											You Are One Step Closer To Your Dream Job
										</p>
									</div>

									<div className='mt-5 sm:mx-auto sm:w-full sm:max-w-sm'>
										<FormContainer
											initialValues={{ email: '' }}
											schema={schema}
											onSubmit={(values: {
												email: string;
												fullName: string;
												phoneNumber: number;
											}) => {
												signUp({
													email: values.email,
													fullName: values.fullName,
													phoneNumber: values.phoneNumber,
												});
											}}
										>
											{(formikProps: any) => {
												const { isValid, dirty, isSubmitting } =
													formikProps;
												return (
													<>
														<div className='mb-4'>
															<InputField
																id='FullName'
																name='fullName'
																label='Full Name'
																placeholder='John'
															/>
															<InputField
																id='PhoneNumber'
																name='phoneNumber'
																label='Phone Number'
																placeholder='+44XXXXXXXX'
															/>
															<InputField
																id='email'
																name='email'
																label='Email'
																placeholder='john@acme.com'
															/>
														</div>
														<Button
															type='submit'
															disabled={!isValid || !dirty}
															isSubmitting={isSubmitting}
															className='flex w-full justify-center disabled:opacity-75 disabled:cursor-not-allowed rounded-md bg-yellow-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
														>
															{storage.getCookie()
																? 'Log In'
																: 'Sign in'}
														</Button>
													</>
												);
											}}
										</FormContainer>
									</div>
								</div>
							</>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
