import { useState } from 'react';
import { useSelector } from 'react-redux';

import { DialogContainer } from '@/components/Elements';
import { selectCurrentQuestionId } from '@/stores/questionSlice';

import { useFetchQuestionsQuery } from '../../../api/apiSlice';
import { QuestionCard } from '../Questions/QuestionCard';

interface QuestionFormProps {
	isOpen: boolean;
}

export const QuestionForm = (props: QuestionFormProps) => {
	const { isOpen } = props;
	const [isOpenModal, setIsOpen] = useState(isOpen);
	const { data } = useFetchQuestionsQuery();
	const questionId = useSelector(selectCurrentQuestionId);

	const closeModal = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<DialogContainer
				isOpen={isOpenModal}
				Title='Profile Exists'
				subTitle='Please continue to edit your Profile'
				buttonText='Edit Profile'
				closeModal={closeModal}
			>
				<div className='m-2'>
					<button
						type='button'
						className='rounded-md bg-yellow-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
						onClick={closeModal}
					>
						Continue
					</button>
				</div>
			</DialogContainer>
			{data?.result ? (
				<>
					<div className='flex flex-row p-8'>
						{data.result.map((elm, index) => {
							return (
								<div className='flex flex-row w-100 items-center' key={elm.Title}>
									<div
										className={
											index <= questionId
												? 'bg-yellow-500 h-[0.5rem] mx-2 rounded w-100'
												: 'bg-gray-200 h-[0.5rem] mx-2 rounded w-100'
										}
									></div>
									<div>
										<span className='relative flex h-2 w-2'>
											<span
												className={
													index === questionId
														? 'animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75'
														: 'absolute inline-flex h-full w-full rounded-full bg-grey-400 opacity-75'
												}
											></span>
											<span
												className={
													index <= questionId
														? 'relative inline-flex rounded-full h-2 w-2 bg-yellow-500'
														: 'relative inline-flex rounded-full h-2 w-2 bg-gray-200'
												}
											></span>
										</span>
									</div>
								</div>
							);
						})}
					</div>
					<QuestionCard></QuestionCard>
				</>
			) : null}
		</>
	);
};
