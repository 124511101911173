import clsx from 'clsx';
import { useField } from 'formik';

import { switchImage } from '@/utils/rangeUtils';

import { FieldWrapper } from './FieldWrapper';

export const RangeInput = (props: any) => {
	const { label, name, className, width, disabled = false, options, min, max, step } = props;
	const [field, meta, helpers] = useField(props);
	const { error, touched } = meta;

	return (
		<FieldWrapper
			error={error}
			touched={touched}
			label={label}
			width={width}
			name={name}
			disabled={disabled}
		>
			<div className='flex flex-col items-center justify-center'>
				<img
					src={switchImage(field.value).image}
					alt='internship'
					className='w-52 h-52 object-contain shadow rounded-lg'
				/>
				<p className='font-semibold my-4 text-gray-600 text-sm'>{field.value}</p>
			</div>

			<input
				className={clsx('w-[85%] bg-gray-100', className)}
				type='range'
				min={min}
				max={max}
				step={step}
				disabled={disabled}
				{...field}
				value={switchImage(field.value).value}
				onChange={(e) => {
					helpers.setValue(options[e.target.value]);
					// field.onChange(options[e.target.value]);
				}}
			/>
			<div className={`grid grid-cols-${max + 1}`}>
				{options &&
					options.map((elm: string, index: number) => (
						<div key={index}>
							<p className='text-gray-500 text-xs'>|</p>
							<p className='text-dark font-bold text-xs'>{elm}</p>
						</div>
					))}
			</div>
		</FieldWrapper>
	);
};
