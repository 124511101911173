export const Rolesoptions = [
	{
		title: 'Software Engineering',
		subOptions: [
			'Backend Engineer',
			'Front-End Engineer',
			'Full Stack Engineer',
			'Software Engineer in Test (QA Engineer)',
			'Software Development Engineer in Test (SDET)',
			'DevOps Engineer',
			'Security Engineer',
			'Mobile Engineer',
			'Game Engineer',
			'Cloud Architect',
			'Sales&Solutions Engineer',
		],
	},
	{
		title: 'Data Engineering',
		subOptions: [
			'Data Analysis & BI',
			'Data Engineer',
			'Data Scientist',
			'Machine Learning Engineer',
			'Research Engineer',
		],
	},
	{
		title: 'Other Engineering',
		subOptions: ['Hardware Engineer', 'IT Support', 'Mechanical Engineer', 'Technical Writer'],
	},
	{
		title: 'Product',
		subOptions: [
			'Delivery Manager & Agile Coach',
			'Product Analysis',
			'Product Management',
			'Technical Product Management',
			'User Research',
		],
	},
	{
		title: 'Design',
		subOptions: [
			'Brand Design',
			'Graphic & Motion Design',
			'Industrial Design',
			'Product Design(UI/UX)',
			'UX Writer',
		],
	},
	{
		title: 'Strategy and operations',
		subOptions: [
			'Business Operations & Strategy',
			'Customer Service & Support',
			'Operations Generalist',
			'Project & Programme Management',
		],
	},
];

export const industriesValues = [
	'B2C',
	'Retail',
	'B2B',
	'AI',
	'Security',
	'Enterprise',
	'MarketPlace',
	'Education',
	'Productivity',
	'Hardware',
	'Real Estate',
	'SaaS',
	'Media',
	'Ecommerce',
	'Science',
];
export const Technologies = [
	'Python',
	'AWS',
	'Java',
	'React',
	'Typescript',
	'Javascript',
	'Docker',
	'Kubernetes',
	'C++',
	'SQL',
	'GO',
	'Linux',
	'Node.js',
	'Git',
	'GraphQL',
	'Redis',
	'Ruby',
	'C#',
	'GCP',
	'Azure',
	'Postgres',
];
