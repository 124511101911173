import clsx from 'clsx';
const widths = {
	col1: 'col-span-1',
	col2: 'col-span-2',
	col3: 'col-span-3',
	col4: 'col-span-4',
	col5: 'col-span-5',
	col6: 'col-span-6',
};

type FieldWrapperProps = {
	label?: string | React.ReactElement;
	name?: string;
	className?: string;
	width?: keyof typeof widths;
	children?: React.ReactNode;
	error?: string;
	touched?: boolean;
	disabled?: boolean;
};

export type FieldWrapperPassThroughProps = Omit<FieldWrapperProps, 'className' | 'children'>;

export const FieldWrapper = (props: any) => {
	const { label, children, name, className, error, touched, disabled = false } = props;
	return (
		<div className={clsx(disabled ? ' opacity-25' : '', className)}>
			<label htmlFor={name} className='block font-medium text-sm text-dark text-left mb-2'>
				{label}
			</label>
			{children}
			{touched && error ? (
				<p className='text-red-700 text-sm mb-2 text-left'>*{error}</p>
			) : null}
		</div>
	);
};
