import './banner.css';
import img1 from '../../assets/img1.jpg';
import img2 from '../../assets/img2.jpg';
import img3 from '../../assets/img3.jpg';
import img4 from '../../assets/img4.jpg';
import img5 from '../../assets/img5.jpg';
import img6 from '../../assets/img6.jpg';
import img7 from '../../assets/img7.jpg';
import Image from '../Image';

const Banner = () => {
	return (
		<div className='banner'>
			<div className='absolute inset-x-0 top-[-10rem] -z-10 transform-gpu  blur-3xl sm:top-[-20rem]'>
				<svg
					className='relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]'
					viewBox='0 0 1155 678'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						fill='url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)'
						fillOpacity='.3'
						d='M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z'
					/>
					<defs>
						<linearGradient
							id='45de2b6b-92d5-4d68-a6a0-9b9b2abad533'
							x1='1155.49'
							x2='-78.208'
							y1='.177'
							y2='474.645'
							gradientUnits='userSpaceOnUse'
						>
							<stop stopColor='#F5D020' />
							<stop offset={1} stopColor='#F5D020' />
						</linearGradient>
					</defs>
				</svg>
			</div>
			<div className='relative overflow-hidden pt-16 sm:h-[48rem] max-[400px]:h-[68rem]'>
				<div className='pt-16 pb-96 sm:pt-48 sm:pb-40 lg:pt-40 lg:pb-48'>
					<div className='relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8'>
						<div className='sm:max-w-lg'>
							<p className='font-bold mt-2 sm:text-4xl text-3xl text-black tracking-tight title text-left px-4 fadedown'>
								Hunting down the finest{' '}
								<span className='talentTitle text-white'>talent</span> for you
							</p>
							<p className='mt-6 text-lg leading-8 text-gray-600 px-4 text-left fadedown'>
								Empowering Your Business With Exceptional Talent
							</p>
						</div>
						<div>
							<div className=''>
								<div
									aria-hidden='true'
									className='pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl'
								>
									<div className='absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8'>
										<div className='flex items-center space-x-6 lg:space-x-8'>
											<div className='grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8'>
												<Image
													src={img1}
													className='h-64 w-44 overflow-hidden rounded-lg imageShadow fadedown sm:opacity-0 lg:opacity-100 object-center'
												/>
												<Image
													src={img2}
													className='h-64 w-44 overflow-hidden rounded-lg imageShadow fadedown  object-center'
												/>
											</div>
											<div className='grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8'>
												<Image
													src={img3}
													className='h-64 w-44 overflow-hidden rounded-lg imageShadow fadedown object-center'
												/>
												<Image
													src={img4}
													className='h-64 w-44 overflow-hidden rounded-lg imageShadow fadedown object-center'
												/>
												<Image
													src={img5}
													className='h-64 w-44 overflow-hidden rounded-lg imageShadow fadedown object-center'
												/>
											</div>
											<div className='grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8'>
												<Image
													src={img6}
													className='h-64 w-44 overflow-hidden rounded-lg imageShadow fadedown object-center'
												/>
												<Image
													src={img7}
													className='object-center h-64 w-44 overflow-hidden rounded-lg imageShadow fadedown'
												/>
											</div>
										</div>
									</div>
								</div>

								<div className='flex py-3'>
									<a
										href='mailto:info@jtrec.co.uk'
										className=' border border-yellow-500 text-lg fadedown flex font-medium items-center justify-center px-4 py-2 rounded-md shadow-sm sm:ml-3 sm:text-sm sm:w-auto text-yellow-500'
									>
										Let's Get In Touch
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Banner;
