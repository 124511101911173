import { createSlice } from '@reduxjs/toolkit';

import { formDataState } from '../types';

import { RootState } from './store';

// Initial state
const initialState: formDataState = {
	userDetails: {
		email: '',
		phoneNumber: 0,
		fullName: '',
	},
	formAnswers: {},
	currentQuestionId: 1,
};

// Creating slice of store
export const QuestionSlice = createSlice({
	name: 'Questions',
	initialState,
	reducers: {
		saveUserDetails: (state, action) => {
			const { email, fullName, phoneNumber } = action.payload;
			state.userDetails = {
				email,
				fullName,
				phoneNumber,
			};
		},
		saveAnswers: (state, action) => {
			state.formAnswers = action.payload;
		},
		saveCurrentQuestionId: (state, action) => {
			const { questionId } = action.payload;
			state.currentQuestionId = questionId;
		},
	},
});

// Exporting actions from slice
export const { saveUserDetails, saveAnswers, saveCurrentQuestionId } = QuestionSlice.actions;

// Selectors
export const selectEmailId = (state: RootState) => state.formData.userDetails;
export const selectCurrentQuestionId = (state: RootState) => state.formData.currentQuestionId;
export const selectAnswers = (state: RootState) => state.formData.formAnswers;
