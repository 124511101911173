import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Fragment, useEffect, useRef, useState } from 'react';

import { FieldWrapper } from '../Form/FieldWrapper';

export const FormDropDown = (props: any) => {
	const { options, onChange, disabled = false, selectedValues = [] } = props;
	const [selected, setSelected] = useState<string[]>(selectedValues);
	const [query, setQuery] = useState('');
	const inputRef = useRef<HTMLInputElement | null>(null);
	// eslint-disable-next-line no-prototype-builtins
	const hasSubOptions = options.every((elm: any) => elm.hasOwnProperty('subOptions'));
	const handleComboboxClick = () => {
		// Focus on the input element when the Combobox is clicked
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};
	const subOptions =
		options && hasSubOptions
			? options.map((elm: { title: string; subOptions: string }) => elm.subOptions).flat()
			: options;

	const filteredSuboptions =
		query === ''
			? subOptions
			: subOptions.filter((option: string) =>
					option
						.toLowerCase()
						.replace(/\s+/g, '')
						.includes(query.toLowerCase().replace(/\s+/g, ''))
			  );

	useEffect(() => {
		setQuery('');
		onChange(selected);
	}, [selected]);
	return (
		<FieldWrapper name={name} disabled={disabled}>
			<div className='m-4 relative'>
				<label
					htmlFor='price'
					className='block font-medium leading-6 text-gray-900 text-left text-sm'
				>
					Please Select Roles:
				</label>
				<div className='relative mt-2 rounded-md shadow-sm'>
					<Combobox
						value={selected}
						disabled={disabled}
						onChange={(elm) => {
							setSelected(elm);
						}}
						multiple
					>
						<div
							onClick={handleComboboxClick}
							onKeyDown={handleComboboxClick}
							role='button'
							tabIndex={-1}
							className='border flex flex-row flex-wrap focus:outline-none focus:ring-0 leading-5 pl-3 pr-10 py-2 rounded-md shadow-md text-gray-900 text-sm'
						>
							{selected.length > 0 && (
								<ul className='flex flex-wrap'>
									{selected.map((option) => (
										<li
											onKeyDown={() => {
												setSelected([
													...selected.filter((elm) => elm !== option),
												]);
											}}
											// eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
											role='button'
											tabIndex={-1}
											onClick={() => {
												setSelected([
													...selected.filter((elm) => elm !== option),
												]);
											}}
											className='border m-1 p-1 rounded'
											key={option}
										>
											<div className='flex flex-row items-center'>
												{option}
												<XMarkIcon className='w-4 h-4 rounded-md text-white ml-2 bg-yellow-500 border'></XMarkIcon>
											</div>
										</li>
									))}
								</ul>
							)}

							<Combobox.Input
								value={query}
								ref={(input) => {
									inputRef.current = input;
								}}
								onChange={(event) => setQuery(event.target.value)}
								className={'focus:outline-none w-full'}
							></Combobox.Input>
						</div>

						<Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
							<ChevronUpDownIcon
								className='h-5 w-5 text-gray-400'
								aria-hidden='true'
							/>
						</Combobox.Button>
						<Transition
							as={Fragment}
							leave='transition ease-in duration-100'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
							afterLeave={() => setQuery('')}
						>
							<Combobox.Options className='absolute mt-1 max-h-60 w-full z-10 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
								{query.length > 0 && (
									<Combobox.Option
										className={({ active }) =>
											`relative cursor-pointer select-none py-2 pl-10 pr-4 ${
												active
													? 'bg-yellow-500 text-white'
													: 'text-gray-900'
											}`
										}
										value={query}
									>
										Create "{query}"
									</Combobox.Option>
								)}
								{filteredSuboptions.length === 0 && query !== '' ? (
									<div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
										Nothing found.
									</div>
								) : (
									filteredSuboptions.map((option: string) => (
										<Combobox.Option
											key={option}
											className={({ active }) =>
												`relative cursor-pointer select-none py-2 pl-10 pr-4 ${
													active
														? 'bg-yellow-500 text-white'
														: 'text-gray-900'
												}`
											}
											value={option}
										>
											{({ selected, active }) => (
												<>
													<span
														className={`block truncate ${
															selected ? 'font-medium' : 'font-normal'
														}`}
													>
														{option}
													</span>
													{selected ? (
														<span
															className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
																active
																	? 'text-white'
																	: 'text-yellow-600'
															}`}
														>
															<CheckIcon
																className='h-5 w-5'
																aria-hidden='true'
															/>
														</span>
													) : null}
												</>
											)}
										</Combobox.Option>
									))
								)}
							</Combobox.Options>
						</Transition>
					</Combobox>
				</div>

				<div className='custom-scrollbar flex flex-wrap flex-grow-0 max-h-40 mt-10 px-2 overflow-y-auto'>
					{subOptions.map((elm: string, index: number) => (
						<span
							onKeyDown={() => setSelected([...selected, elm])}
							role='button'
							tabIndex={-1}
							key={index}
							onClick={() => {
								setSelected([...selected, elm]);
								setQuery('');
							}}
							className={clsx(
								'm-1',
								selected.includes(elm)
									? 'inline-flex items-center rounded-md bg-yellow-500 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-yellow-500'
									: 'inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20'
							)}
						>
							{elm}
						</span>
					))}
				</div>
				{/* <div className='pointer-events-none absolute -inset-x-2.5  h-[20%] bg-gradient-to-t from-white to-white/25 sm:h-[20%]'></div> */}
			</div>
		</FieldWrapper>
	);
};
