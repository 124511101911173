import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { FormContainer } from '@/components/Form/Form';
import { saveAnswers, saveCurrentQuestionId, selectAnswers } from '@/stores/questionSlice';
import { answersObj } from '@/types';

import { Button } from '../Elements/Button';
import { DateField } from '../Form/DateField';
import { RadioGroup } from '../Form/RadioGroup';
import { SelectField } from '../Form/Select';

export const VisaRequirementsForm = (props: any) => {
	const { id } = props;
	const dispatch = useDispatch();
	const answer = useSelector(selectAnswers) as answersObj;
	const initialValues = {
		VisaRequirement: answer.VisaRequirement || 0,
		visaType: answer.visaType || '',
		ExpireDate: answer.expireDate || '',
	};

	const schema = Yup.object().shape({
		VisaRequirement: Yup.string().label('VisaRequirement').required(),
		visaType: Yup.string()
			.label('visaType')
			.when('VisaRequirement', {
				is: '1',
				then: () => Yup.string().label('visaType').required(),
			}),
		ExpireDate: Yup.date()
			.label('ExpireDate')
			.when('VisaRequirement', {
				is: '1',
				then: () => Yup.date().label('ExpireDate').required(),
			}),
	});
	return (
		<>
			<div className='flex items-center justify-center p-5 questionContainer shadow-slate-700'>
				<h1 className='font-bold text-3xl text-left text-white tracking-tight leading-10'>
					Do you require a skilled worker visa to work in the UK?
				</h1>
			</div>
			<FormContainer
				initialValues={initialValues}
				schema={schema}
				onSubmit={(values: any) => {
					if (values.VisaRequirement === '0') {
						values.visaType = '';
						values.ExpireDate = '';
					}
					dispatch(
						saveAnswers({
							...answer,
							...values,
						})
					);
					dispatch(saveCurrentQuestionId({ questionId: id + 1 }));
				}}
			>
				{(formikProps: any) => {
					const { isValid, values } = formikProps;
					return (
						<div className='flex flex-col h-full justify-between'>
							<div className='flex flex-col h-full justify-center optionsContainer p-4'>
								<div className='border flex flex-col h-full p-5 border- border-yellow-400 rounded-lg'>
									<RadioGroup
										groupLabel='Are you eligible for a skilled worker visa to work in the UK?'
										subTitle='If so, please choose your preferred visa type and expiration date below.'
										radioValues={[
											{
												name: 'VisaRequirement',
												label: 'I do not require a visa sponsorship to work in the UK',
												value: '0',
											},
											{
												name: 'VisaRequirement',
												label: 'I Require a visa sponsorship now or in the future to work in the UK',
												value: '1',
											},
										]}
									/>
									<div>
										<p className='font-semibold text-left text-sm mb-2'>
											Could you please provide me with your current visa
											information?
										</p>
										<div className='m-4'>
											<SelectField
												label='Visa Type'
												disabled={values.VisaRequirement == '0'}
												name='visaType'
												handleChange={() => {}}
												options={[
													{
														label: 'Student Visa',
														value: 'student_visa',
													},
													{
														label: 'Dependent Visa',
														value: 'dependent_visa',
													},
												]}
											></SelectField>
											<DateField
												label='Expire Date'
												disabled={values.VisaRequirement == '0'}
												name='ExpireDate'
											></DateField>
										</div>
									</div>
								</div>
							</div>
							<div className='flex flex-row justify-between px-4 pb-4'>
								<Button
									type='button'
									disabled={id === 0}
									className='flex justify-center disabled:opacity-75 disabled:cursor-not-allowed rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-md bg-white'
									onClick={() => {
										dispatch(
											saveCurrentQuestionId({
												questionId: id - 1,
											})
										);
									}}
								>
									Back
								</Button>
								<Button
									type='submit'
									disabled={!isValid}
									className='flex justify-center disabled:cursor-not-allowed rounded-md questionContainer px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm'
								>
									Save & Continue
								</Button>
							</div>
						</div>
					);
				}}
			</FormContainer>
		</>
	);
};
