import clsx from 'clsx';
import { Field, FieldHookConfig, useField } from 'formik';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type RadioButtonProps = FieldWrapperPassThroughProps & {
	className?: string;
};

export const RadioButton = (props: RadioButtonProps & FieldHookConfig<boolean>) => {
	const { label, className } = props;
	const [field, meta] = useField({ ...props, type: 'radio' });
	const { error, touched } = meta;

	return (
		<FieldWrapper error={error} touched={touched}>
			<label className='font-semibold text-sm mb-2 mr-4'>
				<Field className={clsx('mr-2 mb-1', className)} type='radio' {...field} />
				{label}
			</label>
		</FieldWrapper>
	);
};
