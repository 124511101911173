import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { saveAnswers, saveCurrentQuestionId, selectAnswers } from '@/stores/questionSlice';
import { answersObj } from '@/types';

import { Button } from '../Elements/Button';
import { FormContainer } from '../Form/Form';
import { RadioGroup } from '../Form/RadioGroup';
import { RangeInput } from '../Form/RangeInput';

export const CompanySizeForm = (props: any) => {
	const answer = useSelector(selectAnswers) as answersObj;
	const { id } = props;
	const dispatch = useDispatch();
	const initialValues = {
		preferredCompanySizeEnabled: answer.preferredCompanySizeEnabled || 'Yes',
		companySize: answer.companySize || '',
	};
	const schema = Yup.object().shape({
		preferredCompanySizeEnabled: Yup.string().label('preferredCompanySizeEnabled').required(),
		companySize: Yup.string()
			.label('companySize')
			.when('preferredCompanySizeEnabled', {
				is: 'Yes',
				then: () => Yup.string().label('companySize').required(),
			}),
	});
	return (
		<>
			<div className='flex items-center justify-center p-5 questionContainer shadow-slate-700'>
				<h1 className='font-bold text-3xl text-left text-white tracking-tight leading-10'>
					Is there a specific company size that you want to work for?
				</h1>
			</div>
			<FormContainer
				initialValues={initialValues}
				schema={schema}
				onSubmit={(values: any) => {
					dispatch(
						saveAnswers({
							...answer,
							...values,
						})
					);
					dispatch(saveCurrentQuestionId({ questionId: id + 1 }));
				}}
			>
				{(formikProps: any) => {
					const { isValid, values } = formikProps;
					return (
						<div className='flex flex-col h-full justify-between'>
							<div className='flex flex-col h-full justify-center optionsContainer p-4'>
								<div className='border flex flex-col h-full justify-around p-5 rounded-lg'>
									<RadioGroup
										groupLabel='Are you targeting a particular company size for your desired workplace?'
										subTitle='If so, please choose your preferred company size range from the options below.'
										radioValues={[
											{
												name: 'preferredCompanySizeEnabled',
												label: 'Yes',
												value: 'Yes',
											},
											{
												name: 'preferredCompanySizeEnabled',
												label: 'No',
												value: 'No',
											},
										]}
									/>
									<RangeInput
										label='Do you have a preferred company size in mind for your ideal workplace?'
										name='companySize'
										min={0}
										max={4}
										step={1}
										disabled={values.preferredCompanySizeEnabled === 'No'}
										options={[
											'1-10 Micro Org',
											'10-50 StartUp',
											'50-250 Medium-Sized Org',
											'250-1000 Large Org',
											'1000+ Employees',
										]}
									></RangeInput>
								</div>
							</div>
							<div className='flex flex-row justify-between px-4 pb-4'>
								<Button
									type='button'
									disabled={id === 0}
									className='flex justify-center disabled:opacity-75 disabled:cursor-not-allowed rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-md bg-white'
									onClick={() => {
										dispatch(
											saveCurrentQuestionId({
												questionId: id - 1,
											})
										);
									}}
								>
									Back
								</Button>
								<Button
									type='submit'
									disabled={!isValid}
									className='flex justify-center disabled:cursor-not-allowed rounded-md questionContainer px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm'
								>
									Save & Continue
								</Button>
							</div>
						</div>
					);
				}}
			</FormContainer>
		</>
	);
};
