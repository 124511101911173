import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';

import logo from '../../assets/j&t.png';
import { CVWriting } from '../CVWriting/CVWriting';
const navigation = [
	{ name: 'Our Services', href: '#' },
	{ name: 'Contact Us', href: '#' },
];
interface INavbarProps {
	isVisible: boolean;
}

export default function Navbar(props: INavbarProps) {
	const { isVisible } = props;
	const controls = useAnimation();

	const handleScrollTo = (targetId: string) => {
		const targetElement = document.getElementById(targetId);

		if (targetElement) {
			const offset = -50; // Adjust the offset as needed
			const targetPosition = targetElement.offsetTop + offset;

			controls.start({
				y: -200,
				transition: {
					type: 'spring',
					damping: 15,
					stiffness: 100,
				},
			});

			window.scrollTo({
				top: targetPosition,
				behavior: 'smooth',
			});
		}
	};

	useEffect(() => {
		const transition = {
			type: 'spring',
			damping: 15,
			stiffness: 100,
		};

		if (isVisible) {
			controls.start({ y: 0, transition });
		} else {
			controls.start({ y: -200, transition });
		}
	}, [isVisible, controls]);
	return (
		<>
			<motion.div
				className='fixed w-100 bg-[#EAB308] z-50'
				initial={{ y: -200 }}
				animate={controls}
			>
				<div className='mx-auto max-w-7xl px-4'>
					<div className='flex items-center justify-between'>
						<div className='rounded p-1 bg-yellow-500'>
							<img className='w-auto h-12' src={logo} alt='' />
						</div>
						<div className='flex flex-row gap-4'>
							<div
								onClick={() => handleScrollTo('services')}
								onKeyDown={() => handleScrollTo('services')}
								role='button'
								tabIndex={-1}
								className='cursor-pointer text-sm font-semibold leading-6 text-white'
							>
								Our Services
							</div>
							<div
								onClick={() => handleScrollTo('contactUs')}
								onKeyDown={() => handleScrollTo('contactUs')}
								role='button'
								tabIndex={-1}
								className='cursor-pointer text-sm font-semibold leading-6 text-white'
							>
								Contact Us
							</div>
						</div>
					</div>
				</div>
			</motion.div>
			<div className={`absolute w-100 z-50  ${isVisible ? 'hidden' : ''}`}>
				<div className='mx-auto max-w-7xl px-4'>
					<div className='flex items-center justify-between py-2'>
						<div className='rounded border bg-yellow-500'>
							<img className='w-auto h-24' src={logo} alt='' />
						</div>
						{/* <div className='hidden lg:flex lg:gap-x-12 ml-60'>
							<div
								onClick={() => handleScrollTo('services')}
								onKeyDown={() => handleScrollTo('services')}
								role='button'
								tabIndex={-1}
								className='cursor-pointer font-bold text-lg text-white'
							>
								Our Services
							</div>
							<div
								onClick={() => handleScrollTo('contactUs')}
								onKeyDown={() => handleScrollTo('contactUs')}
								role='button'
								tabIndex={-1}
								className='cursor-pointer font-bold text-lg text-white'
							>
								Contact Us
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</>
	);
}
