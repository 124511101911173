import { useState } from 'react';

import './QuestionContainer.css';
import { LoginForm } from './LoginForm/LoginForm';
import { StepsContainer } from './stepsContainer/StepsContainer';

export const QuestionsContainer = () => {
	const [startForm, setStartForm] = useState(false);

	return (
		<div className='mx-auto px-12 py-32 sm:py-48 lg:py-56'>
			<div className='hidden sm:mb-8 sm:flex sm:justify-center'>
				<div className='leading-6 px-3 py-1 relative ring-1 text-yellow-500 ring-yellow-500 rounded-full text-sm'>
					Read more
				</div>
			</div>
			<div className='flex flex-col items-center text-center'>
				<h1 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl'>
					Getting Started is easy.
				</h1>
				<p className='mt-6 text-lg leading-8 text-gray-600'>
					Create a profile and we'll match you with the best your dream companies and jobs
					in the world.
				</p>
				{startForm ? (
					<LoginForm />
				) : (
					<>
						<StepsContainer />
						<div className='mt-10 flex items-center justify-center gap-x-6'>
							<button
								onClick={() => setStartForm(!startForm)}
								className='rounded-md bg-yellow-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
							>
								Get started
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
