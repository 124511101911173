import { EnvelopeIcon, MapPinIcon } from '@heroicons/react/24/outline';

import logo from '../../assets/j&t.png';
import linkedIn from '../../assets/linkedIn.png';

export const Footer = () => {
	return (
		<div className='relative pb-4 pt-8' id='contactUs'>
			<img
				src='https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply'
				alt=''
				className='absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center'
			/>
			<div className=' px-6 lg:px-8'>
				<div className='lg:mx-0 flex flex-col justify-center align-middle items-center'>
					<img className='h-24 rounded-lg w-auto' src={logo} alt='' />
					<h2 className='text-4xl font-bold tracking-tight text-white sm:text-6xl'>
						J&T Recruitment
					</h2>
					<p className='mt-6 text-lg leading-8 text-gray-300'>
						Recruitment Made Simple, Attract And Retain Talent.
					</p>
					<div className='flex justify-center items-center mt-2'>
						<EnvelopeIcon
							className='h-5 w-5 flex-shrink-0 text-gray-400'
							aria-hidden='true'
						/>
						<a
							href='mailto:info@jtrec.co.uk'
							className='text-lg fadedown flex font-medium items-center justify-center px-2 py-2 shadow-sm sm:text-sm sm:w-auto text-white'
						>
							info@jtrec.co.uk
						</a>
					</div>
					{/* <div className='flex justify-center items-center'>
						<div className='flex justify-center items-center'>
							<PhoneIcon
								className='h-5 w-5 flex-shrink-0 text-gray-400'
								aria-hidden='true'
							/>
							<a
								href='#'
								className='text-lg fadedown flex font-medium items-center justify-center px-2 py-2 shadow-sm sm:text-sm sm:w-auto text-white'
							>
								+44 7585860979 / +44 7931159636
							</a>
						</div>
					</div> */}
					<div className='flex justify-center items-center'>
						<MapPinIcon
							className='h-5 w-5 flex-shrink-0 text-gray-400'
							aria-hidden='true'
						/>
						<p className='text-lg fadedown flex font-medium items-center justify-center pl-2 py-2 shadow-sm sm:text-sm sm:w-auto text-white'>
							J&T Recruitment, 9 Bignell Road London SE18 6SB
						</p>
					</div>
				</div>
			</div>
			<div className='lg:pt-2 col-span-2'>
				<div className='h-px flex-auto bg-gray-100' />
				<div className='flex flex-row items-center justify-between my-3'>
					<div>
						<p className='px-5 text-white text-sm'>
							© 2023 J&T Recruitment Ltd. All rights reserved.
						</p>
					</div>
					<div>
						<div className='flex mr-5'>
							<a
								href='https://www.linkedin.com/company/j-t-recruitment/'
								target='_blank'
								rel='noreferrer'
							>
								<img src={linkedIn} alt='linkedIn' className='h-10 m-4'></img>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
