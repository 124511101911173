import { useEffect, useState } from 'react';

import { PrivacyPolicy } from '../PrivacyPolicy/PrivacyPolicy';

import { StepsContainer } from './stepsContainer/StepsContainer';

export const QuestionContainerGoogleForm = () => {
	const [isAccepted, setIsAccepted] = useState(false);
	const [isOpenModal, setIsOpen] = useState(false);

	const closeModal = (value: boolean) => {
		setIsAccepted(value);
		setIsOpen(false);
	};

	useEffect(() => {
		if (isAccepted) {
			window.open('https://forms.gle/EaLo6CYVwGU1p3PR6', '_blank');
		}
	}, [isAccepted]);
	return (
		<div className='mx-auto'>
			<div className='flex flex-col items-center text-center'>
				<h1 className='text-lg font-semibold leading-8 tracking-tight text-yellow-500'>
					Are You A Job Seeker Looking For Your Next Opportunity?
				</h1>
				<h2 className='text-4xl font-bold tracking-tight mt-6 text-gray-900 sm:text-6xl'>
					Getting Started Is Easy
				</h2>
				<p className='mt-6 text-lg leading-8 text-gray-600'>
					Create your profile and we will match you to your dream job
				</p>
				<StepsContainer />
				<div className='mt-10 flex items-center justify-center gap-x-6'>
					<button
						className='rounded-md bg-yellow-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
						onClick={() => {
							setIsOpen(true);
							setIsAccepted(false);
						}}
					>
						Get started
					</button>
					{isOpenModal && (
						<PrivacyPolicy closeModal={closeModal} isOpenModal={isOpenModal} />
					)}
				</div>
			</div>
		</div>
	);
};
