import { baseApi } from '../lib/baseApi';
import { QuestionResponseObj, signUpRequestObj, signUpResponseObj } from '../types';

export const JTRApi = baseApi.enhanceEndpoints({ addTagTypes: ['session'] }).injectEndpoints({
	endpoints: (build) => ({
		authenticateUser: build.mutation<void, void>({
			query: () => {
				return {
					url: '/user/session',
					method: 'post',
				};
			},
		}),
		fetchQuestions: build.query<QuestionResponseObj, void>({
			query: () => {
				return {
					url: '/question/getAllQuestions',
					method: 'get',
				};
			},
		}),
		fetchJobs: build.query<QuestionResponseObj, void>({
			query: () => {
				return {
					url: '/jobs',
					method: 'get',
				};
			},
		}),
		signUp: build.mutation<signUpResponseObj, signUpRequestObj>({
			query: (body) => {
				return {
					url: '/user/signUp',
					method: 'post',
					body,
				};
			},
		}),
		sendCVDetails: build.mutation<signUpResponseObj, signUpRequestObj>({
			query: (body) => {
				return {
					url: '/user/signUp',
					method: 'post',
					body,
				};
			},
		}),
	}),
});
export const {
	useFetchQuestionsQuery,
	useSignUpMutation,
	useAuthenticateUserMutation,
	useFetchJobsQuery,
	useSendCVDetailsMutation,
} = JTRApi;
