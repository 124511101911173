import CounterComponent from '../Counter/Counter';
import './Stats.css';
const stats = [
	{ id: 1, name: 'Number Of Days We Take To Fill A Role', value: 12, prefix: '' },
	{ id: 2, name: 'Number Of Companies We Have Helped', value: 80, prefix: '+' },
	{ id: 3, name: 'Freelance Recruiters Who Work  For Us', value: 120, prefix: '+' },
];

export default function Stats() {
	return (
		<div className=' text-slate-600/10'>
			<div className='fadedown mx-auto textEffect py-32'>
				<div className='mx-auto max-w-7xl px-6 lg:px-8 bottom-0'>
					<dl className='grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3'>
						{stats.map((stat) => (
							<div key={stat.id} className='mx-auto flex max-w-xs flex-col gap-y-4'>
								<dt className='text-base leading-7 text-gray-600'>{stat.name}</dt>
								<dd className='flex flex-row font-semibold items-center justify-center order-first text-8xl text-gray-900 tracking-tight'>
									<CounterComponent Value={stat.value} />
									{stat.prefix.length > 0 && stat.prefix}
								</dd>
							</div>
						))}
					</dl>
				</div>
			</div>
		</div>
	);
}
