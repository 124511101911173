import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { baseApi } from '../lib/baseApi';

import { QuestionSlice } from './questionSlice';

// Configure the Redux store
export const store = configureStore({
	reducer: {
		formData: QuestionSlice.reducer, // add the ConcreteMixSlice reducer
		[baseApi.reducerPath]: baseApi.reducer, // add the apiSlice reducer
	},
	middleware: (getDefaultMiddleware) => [...getDefaultMiddleware().concat(baseApi.middleware)],
	// add the apiSlice middleware
});

// Define AppDispatch and RootState types
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
setupListeners(store.dispatch);
