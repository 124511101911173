export const testimonials = [
	{
		name: 'Nathan F',
		title: 'Candidate Testimonial',
		role: 'Front End Developer',
		Company: '6B Digital',
		gender: 'M',
		feedback:
			"Throughout the recruitment process, Joel was always available to answer my questions and provide guidance. His professionalism and communication skills were top-notch, which made the whole experience smooth and stress-free. Joel's expertise and dedication clearly played a major role in making all of this happen. Overall, I would highly recommend Joel and J&T Recruitment to anyone who's looking for a new job or career change. His dedication, professionalism and expertise makes him a great recruiter, and I'm confident that he will continue to help people find their dream jobs for years to come. Thank you Joel, for all of your hard work!",
	},
	{
		name: 'Laura James Taylor',
		role: 'Talent And Wellbeing Manager',
		title: 'Client Testimonial',
		Company: 'Infosys',
		gender: 'F',
		feedback:
			'I have found J&T Recruitment extremely helpful and supportive during a recent demanding recruitment process. Not only was the communication great but the candidates supplied were of high caliber and have hit the ground running.',
	},
	{
		name: 'Rachel Steward',
		role: 'Java Developer',
		title: 'Client Testimonial',
		Company: 'Wise',
		gender: 'F',
		feedback:
			"I can't thank Joel enough for the incredible job he did in helping me find my dream job at Wise. From the very beginning, he took the time to understand my goals, strengths, career aspirations, and then worked tirelessly to find opportunities that aligned with my needs. Throughout the entire process, Joel was always available to answer my queries and provide guidance and support. He was further able offer valuable insights into the company culture and hiring process, which in-turn really helped me make my decision. I would highly recommend Joel to anyone who is looking for a skilled and trustworthy recruiter who truly cares about their success.",
	},
	{
		name: 'Mark Richardson',
		role: 'HR Manager',
		title: 'Client Testimonial',
		Company: 'HSBC',
		gender: 'M',
		feedback:
			"I had the pleasure of working with Paul during our company's recent hiring campaign and was incredibly impressed with his professionalism, dedication, and ability to find top talent for our product team. From the very beginning, Paul took the time to understand our specific hiring needs, and then worked diligently to source candidates who not only had the right skills and experience, but also aligned with our company culture and values. His attention to detail and commitment to quality were evident in every step of the recruitment process, from the initial candidate screening to the final interview stage.",
	},
	{
		name: 'Paul Thomas',
		role: 'Backend Engineer',
		title: 'Client Testimonial',
		Company: 'BYJU’S',
		gender: 'M',
		feedback:
			"I'm so grateful to Tia for helping me find my dream job at BYJU’S. Tia was incredibly supportive and responsive throughout the entire recruitment process, always keeping me informed and helping to navigate any challenges that arose. Thanks to her expertise and guidance, I was able to land a position at a company that aligned perfectly with my career goals and values. I would highly recommend Tia to anyone looking for a recruiter who will go above and beyond to help them find the right opportunity.",
	},
	{
		name: 'Elise Wington',
		role: 'Talent Acquisition',
		title: 'Client Testimonial',
		Company: 'Trainline',
		gender: 'F',
		feedback:
			'Our experience working with J&T Recuirtment was outstanding, especially Tino whom we interacted with the most.  He is a true professional who took the time to understand our company culture and our specific hiring needs. He worked tirelessly to find top-notch talent for our technology team, and his attention to detail and commitment to quality were evident in every step of the recruitment process. Thanks to his efforts, we were able to build a team of skilled and passionate technologists who have made a significant impact on our business.',
	},
];
