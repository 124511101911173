import { Form, Formik } from 'formik';

export const FormContainer = (props: any) => {
	const { initialValues, children, onSubmit, schema } = props;

	const handleSubmit = (values: any, actions: any) => {
		onSubmit(values, actions);
	};

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
			{(formikProps: any) => {
				const { handleSubmit } = formikProps;
				return <Form onSubmit={handleSubmit}>{children(formikProps)}</Form>;
			}}
		</Formik>
	);
};
